import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { useDashboardContext } from '~/modules/dashboard/interface/use-dashboard-context'
import { Routes } from '~/modules/routes'

import { MoneyDefaultView } from './money-default-view'

export const PersonalView = observer(() => {
  const t = useTranslations('homePage.dashboardTopPanel')
  const { dashboardFinancialsStore } = useDashboardContext()

  return (
    <div className="relative grid gap-0 max-lg:px-5 md:grid-cols-2 md:gap-4 lg:grid-cols-[minmax(253px,auto)_minmax(296px,auto)_minmax(176px,auto)_minmax(142px,auto)] lg:justify-between lg:gap-0">
      <MoneyDefaultView
        viewType="personal"
        amount={dashboardFinancialsStore.personalRetailSales}
        title={t('personalRetailSales')}
        additionalInfo={t('mtd')}
        redirectTo={Routes.orders.list.path()}
        isAdditionalInfo
        data-test-id="dashboard-personal-retail-sales"
      />
      <MoneyDefaultView
        viewType="personal"
        amount={dashboardFinancialsStore.creditsEarnings}
        title={t('earnings')}
        additionalInfo={t('currentAccountBalance')}
        redirectTo={Routes.settings.accountBalance.path()}
        withRightPadding
        isAdditionalInfo
        data-test-id="dashboard-earnings"
      />
      <MoneyDefaultView
        viewType="personal"
        amount={dashboardFinancialsStore.amountNeeded}
        title={t('youOnlyNeed')}
        additionalInfo={t('toBeActive', {
          days:
            dashboardFinancialsStore.financialsState.data?.active
              .daysRemaining || 0,
        })}
        redirectTo={Routes.orders.list.path()}
        isAdditionalInfo
        data-test-id="dashboard-you-only-need"
      />
      <MoneyDefaultView
        viewType="personal"
        amount={dashboardFinancialsStore.norwexDiscountsValue}
        title={t('norwexDiscounts')}
        additionalInfo={t('expiresSoon', {
          amount: dashboardFinancialsStore.norwexDiscountsExpires,
        })}
        redirectTo={Routes.settings.creditsCoupons.path('product')}
        isAdditionalInfo={
          dashboardFinancialsStore.norwexDiscountsExpiresCents > 0
        }
        data-test-id="dashboard-norwex-discount"
      />
    </div>
  )
})
