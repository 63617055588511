export const dashboardEventsLinks = {
  'en-US': {
    nationalConference: 'https://theresource.norwex.com/national-conference/',
    leadershipConference:
      'https://theresource.norwex.com/leadership-conference/',
    leadersSummit: 'https://theresource.norwex.com/vp-leaders-summit/',
    blackJacketMeeting:
      'https://theresource.norwex.com/na-black-jacket-meeting/',
  },
  'es-US': {
    nationalConference:
      'https://theresource.norwex.com/es_us/national-conference/',
    leadershipConference:
      'https://theresource.norwex.com/es_us/leadership-conference/',
    leadersSummit: 'https://theresource.norwex.com/es_us/vp-leaders-summit/',
    blackJacketMeeting:
      'https://theresource.norwex.com/na-black-jacket-meeting/',
  },
  'en-CA': {
    nationalConference:
      'https://theresource.norwex.com/en_ca/national-conference/',
    leadershipConference:
      'https://theresource.norwex.com/en_ca/leadership-conference/',
    leadersSummit: 'https://theresource.norwex.com/en_ca/vp-leaders-summit/',
    blackJacketMeeting:
      'https://theresource.norwex.com/en_ca/na-black-jacket-meeting/',
  },
  'fr-CA': {
    nationalConference:
      'https://theresource.norwex.com/fr_ca/national-conference/',
    leadershipConference:
      'https://theresource.norwex.com/fr_ca/leadership-conference/',
    leadersSummit: 'https://theresource.norwex.com/fr_ca/vp-leaders-summit/',
    blackJacketMeeting:
      'https://theresource.norwex.com/fr_ca/na-black-jacket-meeting/',
  },
}
