import { GetEventsAtGlanceErrors } from '~/modules/dashboard/core/get-events-at-glance.errors'

import type { GetEventsAtGlanceErrorInstance } from '~/modules/dashboard/core/get-events-at-glance.errors'

export class GetEventsAtGlanceErrorMapper {
  static toErrors(status: number, json: any): GetEventsAtGlanceErrorInstance {
    switch (status) {
      case 400:
        return new GetEventsAtGlanceErrors.BadRequest(json.error.message)

      case 401:
        return new GetEventsAtGlanceErrors.Unathorized(json.error.message)

      case 404:
        return new GetEventsAtGlanceErrors.NotFound(json.errors)

      default:
        return new GetEventsAtGlanceErrors.UnknownError(json)
    }
  }
}
