import { BrightStartAction, UserStore } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { isErr } from '@nori/result'
import { DashboardFinancialsStore } from '~/modules/dashboard/interface/dashboard-financials.store'

import { EventsAtGlanceAdapter } from '../infra/events-at-glance.adapter'
import { FinancialsAdapter } from '../infra/financials.adapter'

import { DashboardStore } from './dashboard.store'
import { EventsAtGlanceStore } from './events-at-glance.store'

@Action()
export class DashboardAction {
  constructor(
    @Inject() private readonly dashboardStore: DashboardStore,
    @Inject()
    private readonly dashboardFinancialsStore: DashboardFinancialsStore,
    @Inject() private readonly financialsAdapter: FinancialsAdapter,
    @Inject() private readonly eventsAtGlanceAdapter: EventsAtGlanceAdapter,
    @Inject() private readonly userStore: UserStore,
    @Inject() private readonly eventsAtGlanceStore: EventsAtGlanceStore,
    @Inject() private readonly brightStartAction: BrightStartAction
  ) {}

  async handleInitialLoad(): Promise<void> {
    const userId = this.userStore.id
    const dsrLogin = this.userStore.currentUser.dsrLogin

    if (!userId || !dsrLogin) return

    this.dashboardStore.setIsLoading(true)

    await Promise.all([
      await this.loadFinancials(dsrLogin, userId),
      this.brightStartAction.handleLoad(
        this.userStore.currentUser.dsrLogin || ''
      ),
      await this.handleLoadEventsAtGlance(dsrLogin),
    ])

    this.dashboardStore.setIsLoading(false)
  }

  async handleLoadPrevEventsPeriod(): Promise<void> {
    const dsrLogin = this.userStore.currentUser.dsrLogin
    if (!dsrLogin) return

    this.eventsAtGlanceStore.eventsAtGlanceState.setIsLoading(true)

    await this.loadEventsAtGlance(dsrLogin, 'previousPeriod')

    this.eventsAtGlanceStore.eventsAtGlanceState.setIsLoading(false)
  }

  async handleLoadNextEventsPeriod(): Promise<void> {
    const dsrLogin = this.userStore.currentUser.dsrLogin
    if (!dsrLogin) return

    this.eventsAtGlanceStore.eventsAtGlanceState.setIsLoading(true)

    await this.loadEventsAtGlance(dsrLogin, 'nextPeriod')

    this.eventsAtGlanceStore.eventsAtGlanceState.setIsLoading(false)
  }

  private async handleLoadEventsAtGlance(dsrLogin: string): Promise<void> {
    this.eventsAtGlanceStore.eventsAtGlanceState.setIsLoading(true)

    await this.loadEventsAtGlance(dsrLogin)

    this.eventsAtGlanceStore.eventsAtGlanceState.setIsLoading(false)
  }

  private async loadFinancials(
    dsrLogin: string,
    userId: number
  ): Promise<void> {
    this.dashboardFinancialsStore.financialsState.setError(undefined)

    const financialsResult = await this.financialsAdapter.getUserFinancials(
      dsrLogin,
      userId
    )

    if (isErr(financialsResult)) {
      this.dashboardFinancialsStore.financialsState.setError(
        financialsResult.error
      )
      return
    }

    this.dashboardFinancialsStore.financialsState.setData(financialsResult.data)
  }

  private async loadEventsAtGlance(
    dsrLogin: string,
    period?: 'previousPeriod' | 'nextPeriod'
  ): Promise<void> {
    const eventsAtGlanceStateData =
      this.eventsAtGlanceStore.eventsAtGlanceState.data

    this.eventsAtGlanceStore.eventsAtGlanceState.setError(undefined)
    this.eventsAtGlanceStore.eventsAtGlanceState.setData(undefined)

    const nextPeriod = period && eventsAtGlanceStateData?.[period]

    const eventsResult = await this.eventsAtGlanceAdapter.getUserEventsAtGlance(
      {
        dsrLogin,
        period: nextPeriod,
      }
    )

    if (isErr(eventsResult)) {
      this.eventsAtGlanceStore.eventsAtGlanceState.setError(eventsResult.error)
      return
    }

    this.eventsAtGlanceStore.eventsAtGlanceState.setData(eventsResult.data)
  }

  handleToggleFinancialsBlur(): void {
    // TODO: add store blur state via adapter and call endpoint
    this.dashboardStore.toggleFinancialsBlurred()
  }
}
