import { DEFAULT_LOCALE, I18nStore } from '@nori/app-kit'
import { Inject, Store } from '@nori/di'

import { usefulLinksData } from './useful-links-data'

import type { LangType } from '@nori/ui-kit'
import type { UsefulLinksValueObject } from '../../core/useful-links.value-object'
import type { UsefulLinksSectionTitleValueObject } from '../../core/useful-links-section-title.value-object'
import type { UsefulLinksTitleValueObject } from '../../core/useful-links-title.value-object'

@Store()
export class DashboardUsefulLinksStore {
  constructor(
    @Inject()
    private readonly i18nStore: I18nStore
  ) {}

  get usefulLinks(): UsefulLinksValueObject {
    const currentLocale: LangType = this.i18nStore.currentLocale

    return Object.entries(usefulLinksData).reduce((acc, item) => {
      const [linksTabName, localeLinks] = item
      const links = Object.entries(localeLinks).map(([linkName, locale]) => ({
        title: linkName as UsefulLinksTitleValueObject,
        link: locale[currentLocale] || locale[DEFAULT_LOCALE] || '#',
      }))
      acc[linksTabName as UsefulLinksSectionTitleValueObject] = {
        title: linksTabName as UsefulLinksSectionTitleValueObject,
        links,
      }
      return acc
    }, {} as UsefulLinksValueObject)
  }
}
