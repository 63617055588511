import { ProductPrice } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useDashboardContext } from '~/modules/dashboard/interface/use-dashboard-context'

import type { MoneyValueObject } from '@nori/app-kit'
import type { ViewTypeValueObject } from '~/modules/dashboard/core/view-type.value-object'

type Props = {
  amount: MoneyValueObject
  viewType: ViewTypeValueObject
}

const textMap: Record<ViewTypeValueObject, string> = {
  personal: 'text-2xl sm:text-4xl',
  leader: 'text-2xl',
}

export const Price = observer(({ amount, viewType }: Props) => {
  const { dashboardStore } = useDashboardContext()

  const priceBlurStyle = dashboardStore.isFinancialsBlurred ? 'blur-md' : ''
  const textStyle = textMap[viewType]

  return (
    <span
      className={`${textStyle} font-bold text-primary-900 ${priceBlurStyle}`}
    >
      <ProductPrice price={amount} />
    </span>
  )
})
