import Image from 'next/image'

import { useTranslations } from '@nori/lang-i18n'

import type { HotNewsEntity } from '../core/hot-news.entity'

type Props = {
  item: HotNewsEntity
}

export const HostNewsListRow = ({ item }: Props) => {
  const t = useTranslations('homePage.dashboardRightSide.hotNews')

  return (
    <div className="mb-5 flex flex-row space-x-6 border-b-2 border-primary-200 pb-5">
      <div className="min-w-24 min-w-24">
        <Image
          alt="No Image Available"
          width={88}
          height={88}
          src={item.coverImage}
        />
      </div>

      <div>
        <h1 className="mb-3 text-base font-bold text-primary-900">
          {item.title}
        </h1>
        <div className="mb-1 text-xs text-primary-900">
          {t('published', { date: item.pubDate })}
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: item.description }}
          className="hot-news-content text-sm"
        />
      </div>
    </div>
  )
}
