import { useI18nContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Link } from '@nori/ui-kit'
import { useDashboardContext } from '~/modules/dashboard/interface/use-dashboard-context'

import { EventItem } from './event-item'

const VIEW_CALENDAR_LINKS = {
  'en-US': 'https://theresource.norwex.com/calendar/',
  'es-US': 'https://theresource.norwex.com/es_us/calendar/',
  'en-CA': 'https://theresource.norwex.com/en_ca/calendar/',
  'fr-CA': 'https://theresource.norwex.com/fr_ca/calendar/',
}

export const EventsSection = observer(() => {
  const { dashboardStore } = useDashboardContext()
  const t = useTranslations('homePage.dashboardLeftSide.events')
  const currentLocale = useI18nContext().i18nStore.currentLocale

  return (
    <div className="mt-7 px-5 md:mt-10">
      <div className="flex justify-between">
        <p className="text-xl font-bold text-primary-900">{t('title')}</p>
        <Link
          href={VIEW_CALENDAR_LINKS[currentLocale]}
          target="_blank"
          weight="bold"
          size="medium"
        >
          {t('viewCalendar')}
        </Link>
      </div>
      <div className="mt-3 flex items-stretch justify-between gap-1 text-center text-white max-md:flex-wrap md:gap-4">
        <EventItem
          href={dashboardStore.dashboardEventsLinks.nationalConference}
          title={t('nationalConference')}
          iconName="Flag"
        />
        <EventItem
          href={dashboardStore.dashboardEventsLinks.leadershipConference}
          title={t('leadershipConference')}
          iconName="Flag"
        />
        <EventItem
          href={dashboardStore.dashboardEventsLinks.leadersSummit}
          title={t('leadersSummit')}
          iconName="PaperAirplane"
        />
        {currentLocale !== 'es-US' && (
          <EventItem
            href={dashboardStore.dashboardEventsLinks.blackJacketMeeting}
            title={t('blackJacketMeeting')}
            iconName="PaperAirplane"
          />
        )}
      </div>
    </div>
  )
})
