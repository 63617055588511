import { Icon } from '@nori/ui-kit'

import type { IconName } from '@nori/ui-kit'

type Props = {
  title: string
  iconName: IconName
  href: string
}

export function EventItem({ title, iconName, href }: Props) {
  return (
    <>
      <a
        className="flex w-full flex-col items-center justify-center bg-retail-500 px-2 py-5"
        rel="noreferrer"
        href={href}
        target="_blank"
      >
        <Icon name={iconName} />
        <p className="text-sm font-bold">{title}</p>
      </a>
    </>
  )
}
