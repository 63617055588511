import { DataState, Store } from '@nori/di'

import type { HotNewsEntity } from '../core/hot-news.entity'
import type { HotNewsErrorInstance } from '../core/hot-news.errors'

@Store()
export class HotNewsStore {
  hotNewsList = new DataState<HotNewsEntity[], HotNewsErrorInstance>({
    isLoading: false,
  })
}
