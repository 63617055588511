import type { EventsAtGlanceValueObject } from '../core/events-at-glance.value-object'
import type { GetEventsAtGlanceResponse } from './types/get-events-at-glance.response'

export class EventsAtGlanceMapper {
  static toEventsAtGlanceValueObject(
    response: GetEventsAtGlanceResponse
  ): EventsAtGlanceValueObject {
    return {
      currentPeriod: new Date(response.current_period),
      previousPeriod: new Date(response.previous_period),
      nextPeriod: response.next_period
        ? new Date(response.next_period)
        : undefined,
      personalHeldPartyCount: response.personal_held_party_count,
      personalScheduledPartyCount: response.personal_scheduled_party_count,
      personalGroupHeldPartyCount: response.personal_group_held_party_count,
      personalGroupScheduledPartyCount:
        response.personal_group_scheduled_party_count,
      downlineHeldPartyCount: response.downline_held_party_count,
      downlineScheduledPartyCount: response.downline_scheduled_party_count,
    }
  }
}
