import { useTranslations } from '@nori/lang-i18n'
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableTh,
} from '@nori/ui-kit'

import type { EventsAtGlanceValueObject } from '~/modules/dashboard/core/events-at-glance.value-object'

type Props = {
  eventsAtGlance: EventsAtGlanceValueObject
}

export function EventsTable({ eventsAtGlance }: Props) {
  const t = useTranslations('homePage.dashboardRightSide.eventsAtGlance')
  return (
    <Table widthType="full" type="auto" skin="small">
      <TableHeader>
        <TableTh />
        <TableTh>
          <span className="ml-auto text-xs font-bold text-gray-700 lg:text-sm">
            {t('held')}
          </span>
        </TableTh>
        <TableTh>
          <span className="ml-auto text-xs font-bold text-gray-700 lg:text-sm">
            {t('scheduled')}
          </span>
        </TableTh>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableCell>
            <span className="block py-1 text-xs font-bold text-black lg:text-sm">
              {t('personal')}
            </span>
          </TableCell>
          <TableCell>
            <div className="text-right text-xs text-black lg:text-sm">
              {eventsAtGlance?.personalHeldPartyCount || 0}
            </div>
          </TableCell>
          <TableCell>
            <div className="text-right text-xs text-black lg:text-sm">
              {eventsAtGlance?.personalScheduledPartyCount || 0}
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <span className="block py-1 text-xs font-bold text-black lg:text-sm">
              {t('personalGroup')}
            </span>
          </TableCell>
          <TableCell>
            <div className="text-right text-xs text-black lg:text-sm">
              {eventsAtGlance?.personalGroupHeldPartyCount || 0}
            </div>
          </TableCell>
          <TableCell>
            <div className="text-right text-xs text-black lg:text-sm">
              {eventsAtGlance?.personalGroupScheduledPartyCount || 0}
            </div>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>
            <span className="block py-1 text-xs font-bold text-black lg:text-sm">
              {t('commissionableDownline')}
            </span>
          </TableCell>
          <TableCell>
            <div className="text-right text-xs text-black lg:text-sm">
              {eventsAtGlance?.downlineHeldPartyCount || 0}
            </div>
          </TableCell>
          <TableCell>
            <div className="text-right text-xs text-black lg:text-sm">
              {eventsAtGlance?.downlineScheduledPartyCount || 0}
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
