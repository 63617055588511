import { observer } from '@nori/di'

import { BarLinksDesktop } from './bar-links/bar-links.desktop'
import { BarLinksMobile } from './bar-links/bar-links.mobile'

export const BarLinks = observer(() => {
  return (
    <div data-test-id="bar-links-container">
      <div className="lg:hidden">
        <BarLinksMobile />
      </div>
      <div className="hidden lg:block">
        <BarLinksDesktop />
      </div>
    </div>
  )
})
