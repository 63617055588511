import { useEffect } from 'react'

import { useI18nContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Loader, Swap } from '@nori/ui-kit'

import { useHotNewsContext } from '../interface/hot-news-context'

import { HotNewsList } from './hot-news-list'

const NEWS_URLS: { [key: string]: string } = {
  'en-US': 'https://theresource.norwex.com/news/',
  'es-US': 'https://theresource.norwex.com/es_us/news/',
  'en-CA': 'https://theresource.norwex.com/en_ca/news/',
  'fr-CA': 'https://theresource.norwex.com/fr_ca/news/',
}
export const HotNews = observer(() => {
  const t = useTranslations('homePage.dashboardRightSide.hotNews')
  const { hotNewsAction, hotNewsStore } = useHotNewsContext()

  const currentLocale = useI18nContext().i18nStore.currentLocale
  useEffect(() => {
    hotNewsAction.handleInitialLoad(currentLocale)
  }, [currentLocale])

  if (hotNewsStore.hotNewsList.isLoading) {
    return (
      <div className="mt-8">
        <Loader />
      </div>
    )
  }

  return (
    <Swap
      is={!hotNewsStore.hotNewsList?.data?.length}
      isSlot={<p>{t('empty')}</p>}
    >
      <HotNewsList
        news={hotNewsStore.hotNewsList.data}
        newsUrl={NEWS_URLS[currentLocale]}
      />
    </Swap>
  )
})
