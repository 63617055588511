import { useProfileContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Link } from '@nori/ui-kit'
import { useDashboardContext } from '~/modules/dashboard/interface/use-dashboard-context'

import type { IconName } from '@nori/ui-kit'

export const BarLinksMobile = observer(() => {
  const t = useTranslations()
  const { dashboardAction, dashboardStore } = useDashboardContext()
  const { userStore } = useProfileContext()

  const icon: IconName = dashboardStore.isFinancialsBlurred ? 'EyeOff' : 'Eye'

  return (
    <div>
      <div className="mx-auto flex max-w-top-section-content flex-col gap-1 bg-primary-50 px-5 py-4">
        <div className="flex flex-row items-center gap-1.5 text-xs font-bold text-gray-600">
          {t('header.bottomNav.status')}:{' '}
          <span className="truncate font-medium capitalize text-black">
            {userStore.nemusStatus} | {userStore.careerRank}
          </span>
        </div>
        <div className="flex min-w-0 flex-1 items-center gap-1">
          {userStore.personalLink && (
            <>
              <span className="text-xs font-bold text-primary-900">
                {t('homePage.barLink.personalWebsite')}
              </span>
              <div className="flex min-w-0 flex-1">
                <Link
                  data-test-id="dashboard-pws-link"
                  href={userStore.personalLink?.url}
                  isTruncate
                  justifyContent="normal"
                >
                  {userStore.personalLink?.title}
                </Link>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="flex flex-row-reverse px-5 pt-2">
        <Link
          iconRight={icon}
          isUnderline={false}
          weight="bold"
          onClick={dashboardAction.handleToggleFinancialsBlur}
          data-test-id="toggle-financials-blur-link"
        >
          {t('homePage.barLink.Financials')}
        </Link>
      </div>
    </div>
  )
})
