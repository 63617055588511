import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Link as HyperLink } from '@nori/ui-kit'
import { useDashboardContext } from '~/modules/dashboard/interface/use-dashboard-context'

import { EventsLoading } from './events-loading'
import { EventsNoData } from './events-no-data'
import { EventsTable } from './events-table'

export const EventsAtAGlance = observer(() => {
  const t = useTranslations('homePage.dashboardRightSide.eventsAtGlance')
  const { eventsAtGlanceStore, dashboardAction } = useDashboardContext()
  const { eventsAtGlanceState, isFutureDatePeriodSet } = eventsAtGlanceStore
  const { isLoading } = eventsAtGlanceState

  if (isLoading) return <EventsLoading />

  if (!eventsAtGlanceStore.eventsAtGlanceState.data) return null

  return (
    <div className="w-full max-md:px-5">
      <div className="w-full bg-primary-50 px-8 py-6 ">
        <h3 className="mb-3 text-base font-bold text-primary-900 lg:text-lg">
          {t('eventsAtGlance')} ({eventsAtGlanceStore.currentPeriod})
        </h3>
        <EventsTable
          eventsAtGlance={eventsAtGlanceStore.eventsAtGlanceState.data}
        />
        <div className="mt-7 flex justify-between">
          <div className="leading-none">
            {eventsAtGlanceState.data?.previousPeriod &&
              isFutureDatePeriodSet && (
                <HyperLink
                  size="medium"
                  weight="bold"
                  onClick={dashboardAction.handleLoadPrevEventsPeriod}
                >
                  {t('prevMonth')}
                </HyperLink>
              )}
          </div>
          <div className="leading-none">
            {eventsAtGlanceState.data?.nextPeriod && (
              <HyperLink
                size="medium"
                weight="bold"
                onClick={dashboardAction.handleLoadNextEventsPeriod}
              >
                {t('nextMonth')}
              </HyperLink>
            )}
          </div>
        </div>
      </div>
    </div>
  )
})
