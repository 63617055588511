import { useMemo } from 'react'
import Link from 'next/link'

import { getLink, getMoneyWithIsoCurrency } from '@nori/app-kit'
import { useProfileContext } from '@nori/app-kit'
import { format } from '@nori/date'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Link as HyperLink, LinkClipboardCopy } from '@nori/ui-kit'
import { usePartiesListContext } from '~/modules/parties/interface/use-parties-list-context'
import { Routes } from '~/modules/routes'

import type { PartyEntity } from '~/modules/parties/core/entities/party.entity'

type Props = {
  event: PartyEntity
}

export const OpenEventsListRow = observer(({ event }: Props) => {
  const t = useTranslations('homePage.dashboardRightSide.openEvents')
  const { partiesListAction } = usePartiesListContext()

  const locationString = useMemo(() => {
    if (event.location === 'online') return t('list.online')
    return t('list.inPerson')
  }, [event.location, t])

  const { userStore } = useProfileContext()

  const eventLink = useMemo(() => {
    return getLink({
      type: 'bo',
      personalLink: event.partyPath,
      isMask: true,
    })
  }, [event])

  return (
    <div className="mb-6 mr-4 grid grid-cols-[2fr_repeat(2,1fr)] gap-x-2 border-b border-solid border-gray-300 pb-4 max-lg:gap-x-10">
      <div className="flex flex-col">
        <span className="text-sm font-bold text-primary-900">
          {event.partyName}
        </span>
        <span className="mt-1 text-xs text-primary-900">
          {format(event.partyAt, 'EEEE MM/dd/yyyy @ h:mm b')}
        </span>
        <span className="mt-2 text-xs text-primary-900">{locationString}</span>
      </div>

      <div className="flex flex-col">
        <span className="text-xs font-medium text-gray-700">
          {t('list.retailSales')}
        </span>
        <span className="mt-1 text-sm font-bold text-gray-700">
          {getMoneyWithIsoCurrency(event.placedTotalRetailSales)}
        </span>
      </div>

      <div className="flex justify-end">
        <div className="flex flex-col">
          <div className="text-left text-xs font-bold text-primary-900 underline">
            <HyperLink onClick={() => partiesListAction.handleAddOrder(event)}>
              {t('list.addOrder')}
            </HyperLink>
          </div>
          <div className="mt-1 text-left text-xs font-bold text-primary-900 underline">
            {userStore.currentUser.isPwsEnabled && eventLink.url && (
              <LinkClipboardCopy value={eventLink.url} />
            )}
          </div>
          <Link
            className="mt-1 text-left text-xs font-bold text-primary-900 underline"
            href={Routes.events.view.path(event.id)}
          >
            {t('list.viewEvent')}
          </Link>
        </div>
      </div>
    </div>
  )
})
