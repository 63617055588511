import { useTranslations } from '@nori/lang-i18n'
import { Link as HyperLink } from '@nori/ui-kit'

export function EventsLoading() {
  const t = useTranslations('homePage.dashboardRightSide.eventsAtGlance')
  return (
    <div className="shimmer w-full max-md:px-5">
      <div className="w-full bg-primary-50 px-8 py-6">
        <h3 className="mb-3 text-base font-bold text-primary-900 lg:text-lg">
          {t('eventsAtGlance')}
        </h3>
        <table className="w-full table-auto border-collapse text-xs">
          <thead>
            <tr>
              <th>
                <div className="flex items-center" />
              </th>
              <th>
                <div className="flex items-center">
                  <span className="ml-auto text-xs font-bold text-gray-700 lg:text-sm">
                    {t('held')}
                  </span>
                </div>
              </th>
              <th>
                <div className="flex items-center">
                  <span className="ml-auto text-xs font-bold text-gray-700 lg:text-sm">
                    {t('scheduled')}
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b border-gray-500 last:border-b-0">
              <td className="py-1 ">
                <span className="block py-1 text-xs font-bold text-black lg:text-sm">
                  {t('personal')}
                </span>
              </td>
              <td className="py-1 ">
                <div className="text-right text-xs text-black lg:text-sm" />
              </td>
              <td className="py-1 ">
                <div className="text-right text-xs text-black lg:text-sm" />
              </td>
            </tr>
            <tr className="border-b border-gray-500 last:border-b-0">
              <td className="py-1 ">
                <span className="block py-1 text-xs font-bold text-black lg:text-sm">
                  {t('personalGroup')}
                </span>
              </td>
              <td className="py-1 ">
                <div className="text-right text-xs text-black lg:text-sm" />
              </td>
              <td className="py-1 ">
                <div className="text-right text-xs text-black lg:text-sm" />
              </td>
            </tr>
            <tr className="border-b border-gray-500 last:border-b-0">
              <td className="py-1 ">
                <span className="block py-1 text-xs font-bold text-black lg:text-sm">
                  {t('commissionableDownline')}
                </span>
              </td>
              <td className="py-1 ">
                <div className="text-right text-xs text-black lg:text-sm" />
              </td>
              <td className="py-1 ">
                <div className="text-right text-xs text-black lg:text-sm" />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="invisible mt-7 flex justify-between">
          <div className="leading-none">
            <HyperLink size="medium" weight="bold">
              {t('prevMonth')}
            </HyperLink>
          </div>
          <div className="leading-none">
            <HyperLink size="medium" weight="bold">
              {t('nextMonth')}
            </HyperLink>
          </div>
        </div>
      </div>
    </div>
  )
}
