import { hookContextFactory } from '@nori/di'

import { DashboardAction } from './dashboard.action'
import { DashboardStore } from './dashboard.store'
import { DashboardFinancialsStore } from './dashboard-financials.store'
import { DashboardUsefulLinksStore } from './dashboard-useful-links'
import { EventsAtGlanceStore } from './events-at-glance.store'

export const { useModuleContext: useDashboardContext } = hookContextFactory({
  dashboardAction: DashboardAction,
  dashboardStore: DashboardStore,
  eventsAtGlanceStore: EventsAtGlanceStore,
  dashboardFinancialsStore: DashboardFinancialsStore,
  dashboardUsefulLinksStore: DashboardUsefulLinksStore,
})
