import Link from 'next/link'

import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Icon } from '@nori/ui-kit'
import { useDashboardContext } from '~/modules/dashboard/interface/use-dashboard-context'
import { Routes } from '~/modules/routes'

import { BlockItem } from '../block-item'
import { Price } from '../price'

import { LeaderTable } from './leader-table'
import { SectionBlock } from './section-block'

export const RightSection = observer(() => {
  const t = useTranslations('homePage.dashboardTopPanel')

  const { dashboardFinancialsStore } = useDashboardContext()

  return (
    <SectionBlock>
      <div className="basis-7/12">
        <BlockItem title={t('myBusiness.title')} titleSize="small">
          <Link
            href={Routes.reports.selectedReport.path('coaching')}
            className="flex items-center gap-2"
          >
            <div className="flex-1">
              <LeaderTable
                organization={
                  dashboardFinancialsStore.financialsState.data
                    ?.organizationRecruit
                }
                personal={
                  dashboardFinancialsStore.financialsState.data?.personalRecruit
                }
              />
            </div>
            <Icon name="ChevronRightOutline" size="lg" />
          </Link>
        </BlockItem>
      </div>
      <BlockItem title={t('norwexDiscounts')}>
        <Link
          href={Routes.settings.creditsCoupons.path('product')}
          className="flex items-center justify-between"
        >
          <Price
            amount={dashboardFinancialsStore.norwexDiscountsValue}
            viewType="leader"
          />
          <Icon name="ChevronRightOutline" size="lg" />
        </Link>
        <span className="text-xs font-medium text-red-700">
          {dashboardFinancialsStore.norwexDiscountsExpiresCents > 0 &&
            t('expiresSoon', {
              amount: dashboardFinancialsStore.norwexDiscountsExpires,
            })}
        </span>
      </BlockItem>
    </SectionBlock>
  )
})
