import Link from 'next/link'

import { Icon } from '@nori/ui-kit'

import { AdditionalInfo } from '../additional-info'
import { Price } from '../price'

import type { MoneyValueObject } from '@nori/app-kit'

type Props = {
  amount: MoneyValueObject
  linkTitle: string
  redirectTo: string
  priceTitle?: string
}

export function EarningsRow({
  amount,
  linkTitle,
  priceTitle,
  redirectTo,
}: Props) {
  return (
    <Link
      href={redirectTo}
      className="flex justify-between gap-3 max-md:flex-col"
    >
      <div className="flex basis-1/3 flex-col">
        <Price amount={amount} viewType="leader" />
        <AdditionalInfo title={priceTitle} viewType="leader" />
      </div>
      <div className="flex justify-end max-md:justify-start">
        <div className="flex max-h-8 basis-36 items-center">
          <span className="inline-flex text-xs text-primary-900 dark:text-white">
            {linkTitle}
          </span>
        </div>
        <div className="flex-shrink-0">
          <Icon name="ChevronRightOutline" size="lg" />
        </div>
      </div>
    </Link>
  )
}
