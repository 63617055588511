import { useTranslations } from '@nori/lang-i18n'

export function NoEventsBlock() {
  const t = useTranslations('homePage.dashboardRightSide.openEvents')
  return (
    <div>
      <p className="my-10 text-3xl font-normal text-primary-900">
        {t('noEvents')}
      </p>
      <p className="mb-10 text-base font-normal text-black">
        {t('description')}
      </p>
      <div>
        <p className="text-sm font-bold text-black">{t('listTitle')}</p>
        <ul className="ml-8 mt-5 list-disc text-sm font-light">
          <li>{t('listItem1')}</li>
          <li>{t('listItem2')}</li>
          <li>{t('listItem3')}</li>
          <li>{t('listItem4')}</li>
        </ul>
      </div>
    </div>
  )
}
