import { decimalNumberToCents } from '@nori/app-kit'
import { Service } from '@nori/di'

import type { CreditsInfoValueObject } from '@nori/app-kit'
import type { LandingPageReportsValueObject } from '../core/landing-page-reports.value-object'
import type { UserFinancialsValueObject } from '../core/user-financials.value-object'

function getCents(value?: number): number {
  if (!value) return 0
  if (isNaN(value)) return 0
  return decimalNumberToCents(value)
}

@Service()
export class FinancialsMapper {
  toLandingPageReports(report: any): LandingPageReportsValueObject {
    return {
      personalRetailSales: {
        cents: getCents(report?.commissionable_volume),
        currency: 'USD',
      },
      leaderCommissionsMTD: {
        cents: getCents(report?.commission_check_amount),
        currency: 'USD',
      },
      active: {
        amountNeeded: {
          cents: getCents(report?.amount_needed_active),
          currency: 'USD',
        },
        daysRemaining: report?.days_remaining_to_achieve_active,
      },
      isLeader: !!report?.is_leader,
      organizationRecruit: {
        active: report?.active_organizational_recruit,
        inactive: report?.inactive_organizational_recruit,
        engaged: report?.engaged_organizational_recruit,
      },
      personalRecruit: {
        active: report?.active_personal_recruit,
        inactive: report?.inactive_personal_recruit,
        engaged: report?.engaged_personal_recruit,
      },
    }
  }

  toUserFinancials({
    report,
    user,
    credits,
  }: {
    report?: any
    user?: any
    credits: CreditsInfoValueObject
  }): UserFinancialsValueObject {
    return {
      ...this.toLandingPageReports(report),
      earnings: {
        cents: credits.earnings,
        currency: credits.currency,
      },
      perks: {
        expiresDays: user?.days,
        expires: {
          cents: user?.amount,
          currency: user?.currency,
        },
        current: {
          cents: credits.productCredits,
          currency: credits.currency,
        },
      },
    }
  }
}
