import { Button } from '@nori/ui-kit'

import type { IconName } from '@nori/ui-kit'

type Props = {
  title: string
  iconName: IconName
}

export function ButtonSection({ title, iconName }: Props) {
  return (
    <Button
      size="xl"
      widthType="full"
      iconLeft={iconName}
      iconRight="ChevronRightOutline"
      color="retail"
      rounded="unset"
    >
      <span className="text-sm font-medium text-white">{title}</span>
    </Button>
  )
}
