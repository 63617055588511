import { useTranslations } from '@nori/lang-i18n'
import { Link } from '@nori/ui-kit'

import { HostNewsListRow } from './hot-news-list-row'

import type { HotNewsEntity } from '../core/hot-news.entity'

type Props = {
  news: HotNewsEntity[] | undefined
  newsUrl: string | undefined
}

export const HotNewsList = ({ news, newsUrl }: Props) => {
  const containerClassName =
    'overflow-hidden overflow-y-scroll max-h-[549px] flex flex-col'
  const headerClassName = 'text-lg font-bold text-primary-900 mb-4'
  const t = useTranslations('homePage.dashboardRightSide.hotNews')
  return (
    <div className="mt-8 flex flex-1 flex-col px-5 max-md:px-5">
      <div className="flex justify-between">
        <div className={headerClassName}>{t('title')}</div>
        <Link href={newsUrl} size="large" weight="bold" target="_blank">
          {t('viewAll')}
        </Link>
      </div>
      <div className={containerClassName}>
        {!news?.length && (
          <div className="flex h-full items-center justify-center">
            <p>{t('empty')}</p>
          </div>
        )}
        {!!news &&
          news.map((item) => <HostNewsListRow item={item} key={item.guid} />)}
      </div>
    </div>
  )
}
