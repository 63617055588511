import { observer } from '@nori/di'
import { ContentBlock, Loader, useInitialLoad } from '@nori/ui-kit'
import { useDashboardContext } from '~/modules/dashboard/interface/use-dashboard-context'

import { DashboardTabs } from './sections/dashboard-tabs'
import { EventsAtAGlance } from './sections/events-at-a-glance'
import { EventsSection } from './sections/events-section'
import { UsefulLinks } from './sections/useful-links'
import { BarLinks } from './bar-links'
import { DashboardTopPanel } from './dashboard-top-panel'

export const Dashboard = observer(() => {
  const { dashboardAction, dashboardStore } = useDashboardContext()

  useInitialLoad(dashboardAction.handleInitialLoad)

  if (dashboardStore.isLoading)
    return (
      <div className="flex h-64 min-h-full items-center justify-center">
        <Loader />
      </div>
    )

  return (
    <>
      <BarLinks />
      <div className="mb-28 px-0 md:mt-8 lg:mt-12 lg:px-13">
        <div className="absolute bottom-0 left-0 right-0 top-44">
          <div className="absolute bottom-0 left-0 right-0 top-0 bg-[url('/dashboard-image.jpg')] bg-cover bg-top bg-no-repeat max-md:hidden" />
          <div className="absolute bottom-0 left-0 right-0 top-0 bg-gradient-to-b from-transparent from-70% to-white" />
        </div>
        <ContentBlock>
          <DashboardTopPanel />
          <div className="relative grid grid-flow-dense grid-cols-1 max-sm:gap-7 sm:gap-14 md:mt-6 md:grid-cols-2 md:gap-0 lg:mt-13 lg:gap-4">
            <div>
              <UsefulLinks />
              <EventsSection />
            </div>

            <DashboardTabs />

            <div className="visible max-md:hidden" />

            <div>
              <EventsAtAGlance />
            </div>
          </div>
        </ContentBlock>
      </div>
    </>
  )
})
