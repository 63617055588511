import { PageLayout } from '~/modules/page-layout'

import { Dashboard } from './dashboard'

export function DashboardPage({ locale }: { locale: string }) {
  return (
    <PageLayout>
      <Dashboard />
    </PageLayout>
  )
}
