import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { HotNewsErrors } = errorFactory('HotNewsErrors', {
  BadRequest: 'Bad Request',
  UnexpectedError: 'Unexpected Error',
  UnknownError: 'Unknown Error',
})

export type HotNewsErrorInstance = InstanceType<
  KeyOfErrors<typeof HotNewsErrors>
>
