import { dateFormat } from '@nori/app-kit'
import { Service } from '@nori/di'

import type { HotNewsEntity } from '../../core/hot-news.entity'

@Service()
export class HotNewsMapper {
  private extractValue = (item: any, selector: string) => {
    if (selector === 'content:encoded') {
      const content = 'http://purl.org/rss/1.0/modules/content/'
      return item.getElementsByTagNameNS(content, 'encoded')[0].innerHTML || ''
    }
    return item.querySelector(selector)?.textContent || ''
  }

  private extractItems = (xml: string) => {
    return Array.from(
      new window.DOMParser()
        .parseFromString(xml, 'text/xml')
        ?.querySelectorAll('channel item') ?? []
    )
  }

  private extractCoverImage = (item: any) => {
    const regex = /<img[^>]+src="([^">]+)"/i
    const htmlContent = this.extractValue(item, 'content:encoded')
    const match = htmlContent.match(regex)
    const url = match?.[1]?.replace('https://', 'http://')

    return url ?? ''
  }

  public toHotNews(xml: string): HotNewsEntity[] {
    const items = this.extractItems(xml)

    return items.map((item) => ({
      title: this.extractValue(item, 'title'),
      link: this.extractValue(item, 'link'),
      pubDate: dateFormat(this.extractValue(item, 'pubDate'), 'M/d/yy'),
      guid: this.extractValue(item, 'guid'),
      description: this.extractValue(item, 'description'),
      coverImage: this.extractCoverImage(item),
    }))
  }
}
