import { getMoneyWithIsoCurrency, UserStore } from '@nori/app-kit'
import { DataState, Inject, Store } from '@nori/di'

import type { MoneyValueObject } from '@nori/app-kit'
import type { ViewTypeValueObject } from '~/modules/dashboard/core/view-type.value-object'
import type { FinancialsErrorsInstance } from '../core/financials.errors'
import type { UserFinancialsValueObject } from '../core/user-financials.value-object'

const DEFAULT_MONEY = {
  cents: 0,
}

@Store()
export class DashboardFinancialsStore {
  constructor(@Inject() private readonly userStore: UserStore) {}

  isLoading = true

  financialsState = new DataState<
    UserFinancialsValueObject,
    FinancialsErrorsInstance
  >({
    isLoading: false,
  })

  get defaultMoney(): MoneyValueObject {
    return {
      ...DEFAULT_MONEY,
      currency: this.userStore.currency,
    }
  }

  get viewState(): ViewTypeValueObject {
    if (this.financialsState.data?.isLeader) return 'leader'
    return 'personal'
  }

  get personalRetailSales(): MoneyValueObject {
    return this.financialsState.data?.personalRetailSales || this.defaultMoney
  }

  get amountNeeded(): MoneyValueObject {
    return this.financialsState.data?.active.amountNeeded || this.defaultMoney
  }

  get leaderCommissionsMTD(): MoneyValueObject {
    return this.financialsState.data?.leaderCommissionsMTD || this.defaultMoney
  }

  get creditsEarnings(): MoneyValueObject {
    return this.financialsState.data?.earnings || this.defaultMoney
  }

  get norwexDiscountsValue(): MoneyValueObject {
    return this.financialsState.data?.perks.current || this.defaultMoney
  }

  get norwexDiscountsExpires(): string {
    return getMoneyWithIsoCurrency(
      this.financialsState.data?.perks.expires || this.defaultMoney
    )
  }

  get norwexDiscountsExpiresCents(): number {
    return (
      this.financialsState.data?.perks.expires.cents || this.defaultMoney.cents
    )
  }
}
