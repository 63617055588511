import { useTranslations } from '@nori/lang-i18n'
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableTh,
} from '@nori/ui-kit'

import { TableItem } from './table-item'

import type { RecruitDetailsValueObject } from '~/modules/dashboard/core/recruit-details.value-object'

type Props = {
  personal?: RecruitDetailsValueObject
  organization?: RecruitDetailsValueObject
}

export function LeaderTable({ personal, organization }: Props) {
  const t = useTranslations('homePage.dashboardTopPanel')

  return (
    <Table widthType="full" type="auto" skin="small">
      <TableHeader>
        <TableTh></TableTh>
        <TableTh justifyContent="end">
          <div className="text-right">
            {t('myBusiness.header.persRecruits')}
          </div>
        </TableTh>
        <TableTh justifyContent="end">
          <div className="w-70/100 text-right">
            {t('myBusiness.header.commissionsDownline')}
          </div>
        </TableTh>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableCell>
            <span className="font-bold">{t('myBusiness.engaged')}</span>
          </TableCell>
          <TableCell>
            <TableItem value={personal?.engaged} />
          </TableCell>
          <TableCell>
            <TableItem value={organization?.engaged} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <span className="font-bold">{t('myBusiness.active')}</span>
          </TableCell>
          <TableCell>
            <TableItem value={personal?.active} />
          </TableCell>
          <TableCell>
            <TableItem value={organization?.active} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <span className="font-bold">{t('myBusiness.inactive')}</span>
          </TableCell>
          <TableCell>
            <TableItem value={personal?.inactive} />
          </TableCell>
          <TableCell>
            <TableItem value={organization?.inactive} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
