import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Link, Loader, Swap, useInitialLoad, useMount } from '@nori/ui-kit'
import { usePartiesListContext } from '~/modules/parties/interface/use-parties-list-context'
import { Routes } from '~/modules/routes'

import { NoEventsBlock } from './no-events-block'
import { OpenEventsList } from './open-events-list'

export const OpenEvents = observer(() => {
  const t = useTranslations('homePage.dashboardRightSide.openEvents')
  const { partiesListStore, partiesListAction } = usePartiesListContext()
  const { usedPartiesList } = partiesListStore

  useInitialLoad(() => {
    partiesListAction.handleInitialLoad('open')
  })

  if (partiesListStore.isLoading) {
    return (
      <div className="mt-8">
        <Loader />
      </div>
    )
  }

  return (
    <div className="mx-5 mt-5 flex flex-1 flex-col md:mx-6 md:mt-8">
      <div className="flex justify-between pr-4">
        <p className="text-lg font-bold text-primary-900">{t('title')}</p>
        <Link href={Routes.events.list.path()} weight="bold" size="large">
          {t('allEvents')}
        </Link>
      </div>
      <Swap is={!usedPartiesList?.length} isSlot={<NoEventsBlock />}>
        <OpenEventsList events={usedPartiesList} />
      </Swap>
    </div>
  )
})
