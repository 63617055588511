import { useProfileContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Link } from '@nori/ui-kit'
import { useDashboardContext } from '~/modules/dashboard/interface/use-dashboard-context'

import type { IconName } from '@nori/ui-kit'

export const BarLinksDesktop = observer(() => {
  const t = useTranslations('homePage.barLink')
  const { dashboardAction, dashboardStore } = useDashboardContext()
  const { userStore } = useProfileContext()

  const icon: IconName = dashboardStore.isFinancialsBlurred ? 'EyeOff' : 'Eye'

  return (
    <div className="h-6 lg:bg-primary-50">
      <div className="mx-auto flex max-w-top-section-content gap-1 px-5">
        <div className="flex min-w-0 flex-1 items-center gap-1">
          {userStore.personalLink && (
            <>
              <span className="text-base font-bold text-primary-900">
                {t('personalWebsite')}
              </span>
              <div className="min-w-0 flex-1">
                <Link
                  data-test-id="dashboard-pws-link"
                  href={userStore.personalLink?.url}
                  isTruncate
                  justifyContent="normal"
                >
                  {userStore.personalLink?.title}
                </Link>
              </div>
            </>
          )}
        </div>
        <div>
          <Link
            iconRight={icon}
            isUnderline={false}
            weight="bold"
            onClick={dashboardAction.handleToggleFinancialsBlur}
            data-test-id="toggle-financials-blur-link"
          >
            {t('Financials')}
          </Link>
        </div>
      </div>
    </div>
  )
})
