import { useEffect, useState } from 'react'

import { NewConsultants, useBrightStartContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Tabs } from '@nori/ui-kit'
import { HotNews } from '~/modules/hot-news/ui/hot-news'

import { OpenEvents } from './open-events'

import type { DashboardTabsLabelValueObject } from '../../../core'

export const DashboardTabs = observer(() => {
  const t = useTranslations('homePage.dashboardRightSide')
  const { brightStartStore } = useBrightStartContext()

  const isConsultantsVisible = brightStartStore.isAvailable
  useEffect(() => {
    setActiveTab(isConsultantsVisible ? 'new-consultants' : 'hot-news')
  }, [isConsultantsVisible])
  const [activeTab, setActiveTab] =
    useState<DashboardTabsLabelValueObject>('new-consultants')
  return (
    <div className="flex flex-col bg-primary-50/80">
      <Tabs<DashboardTabsLabelValueObject>
        active={activeTab}
        onChange={setActiveTab}
        skin="dash-row"
      >
        <div className="opacity-90">
          <Tabs.Titles>
            {isConsultantsVisible && (
              <Tabs.Title name="new-consultants">
                {t('tabs.newConsultants')}
              </Tabs.Title>
            )}
            <Tabs.Title name="hot-news">{t('tabs.hotNews')}</Tabs.Title>
            <Tabs.Title name="open-events">{t('tabs.openEvents')}</Tabs.Title>
          </Tabs.Titles>
        </div>
        {isConsultantsVisible && (
          <Tabs.Panel name="new-consultants">
            <NewConsultants type="all-recruits" />
          </Tabs.Panel>
        )}
        <Tabs.Panel name="open-events">
          <OpenEvents />
        </Tabs.Panel>
        <Tabs.Panel name="hot-news">
          <HotNews />
        </Tabs.Panel>
      </Tabs>
    </div>
  )
})
