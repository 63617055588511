import type { PropsWithChildren } from 'react'

type TitleSize = 'small' | 'normal'

type Props = {
  title: string
  titleSize?: TitleSize
  withRightPadding?: boolean
  'data-test-id'?: string
}

const titleSizeMap = {
  normal: 'text-sm',
  small: 'text-xs',
}

export function BlockItem({
  title,
  children,
  withRightPadding,
  titleSize = 'normal',
  'data-test-id': dataTestId,
}: PropsWithChildren<Props>) {
  const titleFontSize = titleSizeMap[titleSize]
  const rightPadding = withRightPadding ? 'lg:pr-16' : ''

  return (
    <div
      data-test-id={dataTestId}
      className={`flex-1 max-md:border-b max-md:border-gray-400 max-md:py-4 ${rightPadding}`}
    >
      <p
        className={`mb-2 whitespace-nowrap max-md:mb-1 ${titleFontSize} font-bold text-black`}
      >
        {title}
      </p>
      {children}
    </div>
  )
}
