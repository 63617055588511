import {
  CreditsService,
  getBaseUrl,
  RequestService,
  SERVICE_NAMES,
} from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { isErr, resultErr, resultOk } from '@nori/result'

import { FinancialsErrors } from '../core/financials.errors'

import { FinancialsMapper } from './financials.mapper'

import type { PromiseResult } from '@nori/result'
import type { FinancialsErrorsInstance } from '../core/financials.errors'
import type { LandingPageReportsValueObject } from '../core/landing-page-reports.value-object'
import type { UserFinancialsValueObject } from '../core/user-financials.value-object'

@Adapter()
export class FinancialsAdapter {
  reportBaseUrl = getBaseUrl(SERVICE_NAMES.REPORT_SERVICE)
  userBaseUrl = getBaseUrl(SERVICE_NAMES.USER_SERVICE)

  constructor(
    @Inject()
    private readonly requestService: RequestService,
    @Inject()
    private readonly financialsMapper: FinancialsMapper,
    @Inject() private readonly creditsService: CreditsService
  ) {}

  async getLandingReports(
    dsrLogin: string
  ): PromiseResult<LandingPageReportsValueObject, FinancialsErrorsInstance> {
    try {
      const { ok, json } = await this.requestService.secured.get(
        `${this.reportBaseUrl}/ibo/${dsrLogin}/reports/landing-page`,
        {}
      )

      if (!ok)
        return resultErr(new FinancialsErrors.UnexpectedFinancialsDetails(json))

      return resultOk(this.financialsMapper.toLandingPageReports(json))
    } catch (err: unknown) {
      return resultErr(new FinancialsErrors.UnexpectedFinancialsDetails(err))
    }
  }

  private async getDetails(dsrLogin: string, userId: number) {
    try {
      const [reportResult, userResult] = await Promise.all([
        this.requestService.secured.get(
          `${this.reportBaseUrl}/ibo/${dsrLogin}/reports/landing-page`,
          {}
        ),
        this.requestService.secured.get(
          `${this.userBaseUrl}/users/${userId}/credits/product_credits/expires_in_days`,
          {}
        ),
      ])

      return resultOk({
        report: reportResult?.json,
        user: userResult?.json,
      })
    } catch (err: unknown) {
      return resultErr(new FinancialsErrors.UnexpectedFinancialsDetails(err))
    }
  }

  async getUserFinancials(
    dsrLogin: string,
    userId: number
  ): PromiseResult<UserFinancialsValueObject, FinancialsErrorsInstance> {
    try {
      const [details, creditsResult] = await Promise.all([
        this.getDetails(dsrLogin, userId),
        this.creditsService.getCreditsInfo(userId),
      ])

      if (isErr(creditsResult))
        return resultErr(
          new FinancialsErrors.UnknownCreditsFinancials(creditsResult.error)
        )

      return resultOk(
        this.financialsMapper.toUserFinancials({
          report: isErr(details) ? undefined : details.data.report,
          user: isErr(details) ? undefined : details.data.user,
          credits: creditsResult.data,
        })
      )
    } catch (err: unknown) {
      return resultErr(new FinancialsErrors.UnexpectedUserFinancials(err))
    }
  }
}
