import { getBaseUrl, RequestService, SERVICE_NAMES } from '@nori/app-kit'
import { format } from '@nori/date'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'

import { GetEventsAtGlanceErrors } from '../core/get-events-at-glance.errors'

import { GetEventsAtGlanceErrorMapper } from './mappers/error/get-events-at-glance-error.mapper'
import { EventsAtGlanceMapper } from './events-at-glance.mapper'

import type { PromiseResult } from '@nori/result'
import type { EventsAtGlanceValueObject } from '../core/events-at-glance.value-object'
import type { FinancialsErrorsInstance } from '../core/financials.errors'
import type { GetEventsAtGlanceResponse } from './types/get-events-at-glance.response'

@Adapter()
export class EventsAtGlanceAdapter {
  reportBaseUrl = getBaseUrl(SERVICE_NAMES.REPORT_SERVICE)

  constructor(
    @Inject()
    private readonly requestService: RequestService
  ) {}

  async getUserEventsAtGlance({
    dsrLogin,
    period,
  }: {
    dsrLogin: string
    period?: Date
  }): PromiseResult<EventsAtGlanceValueObject, FinancialsErrorsInstance> {
    const url = `${this.reportBaseUrl}/ibo/${dsrLogin}/reports/party-downline-summary`
    const query = new URLSearchParams()
    if (period) query.append('period', format(period, 'yyyy-MM-dd'))

    try {
      const response =
        await this.requestService.secured.get<GetEventsAtGlanceResponse>(
          `${url}?${query.toString()}`
        )

      if (response.ok) {
        return resultOk(
          EventsAtGlanceMapper.toEventsAtGlanceValueObject(response.json)
        )
      }

      return resultErr(
        GetEventsAtGlanceErrorMapper.toErrors(response.status, response.json)
      )
    } catch (err: unknown) {
      return resultErr(new GetEventsAtGlanceErrors.UnexpectedError(err))
    }
  }
}
