import { observer } from '@nori/di'
import { useDashboardContext } from '~/modules/dashboard/interface/use-dashboard-context'

import { LeaderView } from './leader-view/leader-view'
import { PersonalView } from './personal-view'

export const DashboardTopPanel = observer(() => {
  const { dashboardFinancialsStore } = useDashboardContext()

  if (dashboardFinancialsStore.viewState === 'leader') return <LeaderView />

  return <PersonalView />
})
