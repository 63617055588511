import { observer } from '@nori/di'
import { useDashboardContext } from '~/modules/dashboard/interface/use-dashboard-context'

type Props = {
  value?: number
}

export const TableItem = observer(({ value = 0 }: Props) => {
  const { dashboardStore } = useDashboardContext()

  const blurStyle = dashboardStore.isFinancialsBlurred ? 'blur-md' : ''

  return <div className={`text-right ${blurStyle}`}>{value}</div>
})
