import { observer } from '@nori/di'
import { InfiniteScroll, Loader } from '@nori/ui-kit'
import { usePartiesListContext } from '~/modules/parties/interface/use-parties-list-context'

import { OpenEventsListRow } from './open-events-list-row'

import type { PartyEntity } from '~/modules/parties/core/entities/party.entity'

type Props = {
  events: PartyEntity[]
}

export const OpenEventsList = observer(({ events }: Props) => {
  const { partiesListStore, partiesListAction } = usePartiesListContext()

  return (
    <div className="relative flex-1 max-md:max-h-176 max-md:min-h-[39rem]">
      <div
        id="open-events-list-table"
        className="absolute bottom-0 left-0 right-0 top-0 mt-6 flex flex-col overflow-auto"
      >
        <InfiniteScroll
          next={partiesListAction.handleLoadMore}
          dataLength={partiesListStore.usedPartiesList.length}
          // eslint-disable-next-line react/forbid-component-props
          style={{ overflow: 'hidden' }}
          loader={
            partiesListStore.isLoadingNextPage && (
              <div className="mb-6">
                <Loader />
              </div>
            )
          }
          hasMore={partiesListStore.hasMore}
          scrollThreshold={0.5}
          scrollableTarget="open-events-list-table"
        >
          {events.map((event) => (
            <OpenEventsListRow key={event.id} event={event} />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  )
})
