import { LeftSection } from './left-section'
import { RightSection } from './right-section'

export const LeaderView = () => {
  return (
    <div className="relative mx-5 grid grid-cols-1 md:grid-cols-2 md:gap-2 lg:mx-0 lg:gap-12">
      <LeftSection />
      <RightSection />
    </div>
  )
}
