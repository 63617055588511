import type { ViewTypeValueObject } from '~/modules/dashboard/core/view-type.value-object'

type Props = {
  title?: string
  viewType: ViewTypeValueObject
}

const fontMap: Record<ViewTypeValueObject, string> = {
  leader: 'text-xs font-medium text-primary-900',
  personal: 'text-sm lg:font-bold text-black',
}

export function AdditionalInfo({ title, viewType }: Props) {
  if (!title) return <></>

  const fontStyle = fontMap[viewType]

  return <span className={fontStyle}>{title}</span>
}
