import { Action, Inject } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'

import { HotNewsAdapter } from '../infra/hot-news.adapter'

import { HotNewsStore } from './hot-news.store'

import type { LangType } from '@nori/ui-kit'

const logger = createLogger('hot-news.action')

@Action()
export class HotNewsAction {
  constructor(
    @Inject() private readonly hotNewsStore: HotNewsStore,
    @Inject() private readonly hotNewsAdapter: HotNewsAdapter
  ) {}

  async handleInitialLoad(locale: LangType): Promise<void> {
    this.hotNewsStore.hotNewsList.setIsLoading(true)
    const result = await this.hotNewsAdapter.getHotNews(locale)
    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.hotNewsStore.hotNewsList.setError(result.error)
      this.hotNewsStore.hotNewsList.setIsLoading(false)
      return
    }

    this.hotNewsStore.hotNewsList.setData(result.data)
    this.hotNewsStore.hotNewsList.setIsLoading(false)
  }
}
