import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { FinancialsErrors } = errorFactory('FinancialsErrors', {
  UnexpectedFinancialsDetails: 'Unexpected financials details',
  UserFinancials: 'Unexpected financials details',
  UnexpectedUserFinancials: 'Unexpected Error',
  UnknownCreditsFinancials: 'Unknown credits Error',
})

export type FinancialsErrorsInstance = InstanceType<
  KeyOfErrors<typeof FinancialsErrors>
>
