import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'

import { HotNewsErrors } from '../core/hot-news.errors'

import { HotNewsMapper } from './mappers/hot-news.mapper'

import type { PromiseResult } from '@nori/result'
import type { LangType } from '@nori/ui-kit'
import type { HotNewsEntity } from '../core/hot-news.entity'
import type { HotNewsErrorInstance } from '../core/hot-news.errors'

@Adapter()
export class HotNewsAdapter {
  public constructor(@Inject() private readonly hotNewsMapper: HotNewsMapper) {}

  public async getHotNews(
    locale: LangType
  ): PromiseResult<HotNewsEntity[], HotNewsErrorInstance> {
    try {
      const response = await fetch(`/user_service/news?lang=${locale}`)

      if (response.status !== 200) {
        return resultErr(new HotNewsErrors.UnknownError(response))
      }

      const hotNews = this.hotNewsMapper.toHotNews(await response.text())

      return resultOk(hotNews)
    } catch (error) {
      return resultErr(new HotNewsErrors.UnexpectedError(error))
    }
  }
}
