import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { useDashboardContext } from '~/modules/dashboard/interface/use-dashboard-context'
import { Routes } from '~/modules/routes'

import { BlockItem } from '../block-item'
import { MoneyDefaultView } from '../money-default-view'

import { EarningsRow } from './earnings-row'
import { SectionBlock } from './section-block'

export const LeftSection = observer(() => {
  const t = useTranslations('homePage.dashboardTopPanel')

  const { dashboardFinancialsStore } = useDashboardContext()

  return (
    <SectionBlock>
      <MoneyDefaultView
        viewType="leader"
        amount={dashboardFinancialsStore.personalRetailSales}
        title={t('personalRetailSales')}
        additionalInfo={t('mtd')}
        redirectTo={Routes.orders.list.path()}
        isAdditionalInfo
      />

      <div className="basis-7/12">
        <BlockItem title={t('earnings')}>
          <div className="max-md:flex max-md:justify-between max-md:gap-6">
            <EarningsRow
              amount={dashboardFinancialsStore.creditsEarnings}
              linkTitle={t('currentAccountBalance')}
              redirectTo={Routes.settings.accountBalance.path()}
            />
            <div className="mb-3" />
            <EarningsRow
              amount={dashboardFinancialsStore.leaderCommissionsMTD}
              linkTitle={t('fromTheLeaderCommissions')}
              priceTitle={t('mtd')}
              redirectTo={Routes.reports.selectedReport.path(
                'activity',
                'team-stats'
              )}
            />
          </div>
        </BlockItem>
      </div>
    </SectionBlock>
  )
})
