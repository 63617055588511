import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { GetEventsAtGlanceErrors } = errorFactory(
  'GetEventsAtGlanceErrors',
  {
    BadRequest: 'Bad Request',
    NotFound: 'Not Found',
    Unathorized: 'Unathorized',
    UnexpectedError: 'Unexpected Error',
    UnknownError: 'Unknown Error',
  }
)

export type GetEventsAtGlanceErrorInstance = InstanceType<
  KeyOfErrors<typeof GetEventsAtGlanceErrors>
>
