import { I18nStore } from '@nori/app-kit'
import { format } from '@nori/date'
import { DataState, Inject, Store } from '@nori/di'

import type { EventsAtGlanceValueObject } from '../core/events-at-glance.value-object'
import type { GetEventsAtGlanceErrorInstance } from '../core/get-events-at-glance.errors'

@Store()
export class EventsAtGlanceStore {
  constructor(
    @Inject()
    private readonly i18nStore: I18nStore
  ) {}
  eventsAtGlanceState = new DataState<
    EventsAtGlanceValueObject,
    GetEventsAtGlanceErrorInstance
  >()

  get currentPeriod(): string {
    return this.eventsAtGlanceState.data?.currentPeriod
      ? format(this.eventsAtGlanceState.data.currentPeriod, 'LLL yyyy', {
          locale: this.i18nStore.dateFnsLocale,
        })
      : ''
  }

  get isFutureDatePeriodSet(): boolean {
    if (!this.eventsAtGlanceState?.data?.currentPeriod) {
      return false
    }
    const currentDate = new Date()
    return (
      this.eventsAtGlanceState.data.currentPeriod.getMonth() >
      currentDate.getMonth()
    )
  }
}
