import Link from 'next/link'

import { useTranslations } from '@nori/lang-i18n'

import type { LinkItemValueObject } from '~/modules/dashboard/core/useful-links.value-object'
import type { UsefulLinksSectionTitleValueObject } from '~/modules/dashboard/core/useful-links-section-title.value-object'

type Props = {
  title?: UsefulLinksSectionTitleValueObject
  links?: LinkItemValueObject[]
}

export function LinkSection({ title, links }: Props) {
  const t = useTranslations('homePage.dashboardLeftSide.usefulLinks')
  if (!title || !links) return null

  return (
    <div>
      <p className="mb-3 text-xl font-bold text-gray-500">{t(title)}</p>
      <ul>
        {links.map(({ title, link }, index) => {
          return (
            <Link
              key={`${title}-${index}`}
              className="mb-1 block text-sm font-normal text-black"
              href={link}
              target="_blank"
            >
              {t(title)}
            </Link>
          )
        })}
      </ul>
    </div>
  )
}
