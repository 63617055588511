import Link from 'next/link'

import { Icon } from '@nori/ui-kit'

import { AdditionalInfo } from './additional-info'
import { BlockItem } from './block-item'
import { Price } from './price'

import type { MoneyValueObject } from '@nori/app-kit'
import type { ViewTypeValueObject } from '~/modules/dashboard/core/view-type.value-object'

type Props = {
  title: string
  amount: MoneyValueObject
  additionalInfo?: string
  viewType: ViewTypeValueObject
  redirectTo: string
  withRightPadding?: boolean
  isAdditionalInfo?: boolean
  'data-test-id'?: string
}

export function MoneyDefaultView({
  title,
  amount,
  additionalInfo,
  viewType,
  redirectTo,
  withRightPadding,
  isAdditionalInfo,
  'data-test-id': dataTestId,
}: Props) {
  return (
    <BlockItem
      data-test-id={dataTestId}
      title={title}
      withRightPadding={withRightPadding}
    >
      <Link
        href={redirectTo}
        className="flex cursor-pointer items-center justify-between md:mb-1"
      >
        <Price amount={amount} viewType={viewType} />
        <Icon name="ChevronRightOutline" size="lg" />
      </Link>
      {isAdditionalInfo && (
        <AdditionalInfo title={additionalInfo} viewType={viewType} />
      )}
    </BlockItem>
  )
}
