import { useI18nContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button } from '@nori/ui-kit'
import { useDashboardContext } from '~/modules/dashboard/interface/use-dashboard-context'
import { useOrdersListContext } from '~/modules/orders-list/interface/context/use-orders-list-context'

import { ButtonSection } from './button-section'
import { LinkSection } from './link-section'

export const UsefulLinks = observer(() => {
  const t = useTranslations('homePage.dashboardLeftSide.usefulLinks')
  const t2 = useTranslations('orders.list')

  const {
    dashboardUsefulLinksStore: { usefulLinks },
  } = useDashboardContext()
  const { trainingInTheKnow, promotions, productInfo, printCenter, celebrate } =
    usefulLinks
  const { ordersListAction } = useOrdersListContext()

  const THE_RESOURCE_URLS: { [key: string]: string } = {
    'en-US': 'https://theresource.norwex.com/',
    'es-US': 'https://theresource.norwex.com/es_us/',
    'en-CA': 'https://theresource.norwex.com/en_ca',
    'fr-CA': 'https://theresource.norwex.com/fr_ca',
  }

  const TRAINING_LIBRARY_URLS: { [key: string]: string } = {
    'en-US': 'https://training.norwex.com/',
    'es-US': 'https://traininges.norwex.com/',
    'en-CA': 'https://training.norwex.com/',
    'fr-CA': 'https://trainingfr.norwex.com/',
  }

  const currentLocale = useI18nContext().i18nStore.currentLocale

  return (
    <div className="flex flex-col justify-between bg-primary-50/80 pb-6 pt-6">
      <div className="mx-5 flex gap-4 max-lg:flex-col max-md:bg-white">
        <div className="w-full lg:w-[300px]">
          <Button
            onClick={ordersListAction.handleRedirectToCreatePersonalOrder}
            size="xl"
            widthType="full"
          >
            <span className="text-sm font-medium text-white">
              {t2('createPersonalOrder')}
            </span>
          </Button>
        </div>
        <div className="w-full lg:w-[300px]">
          <Button
            onClick={ordersListAction.handleRedirectToCreateOrder}
            size="xl"
            widthType="full"
          >
            <span className="text-sm font-medium text-white">
              {t2('createCustomerOrder')}
            </span>
          </Button>
        </div>
      </div>
      <div className="mx-5 mt-11 flex gap-6 sm:gap-6 md:mx-10 md:gap-10 lg:gap-16">
        <div className="flex flex-1 flex-col gap-6 sm:gap-8">
          <LinkSection {...trainingInTheKnow} />
          <LinkSection {...promotions} />
        </div>
        <div className="flex flex-1 flex-col gap-15 sm:gap-8">
          <LinkSection {...productInfo} />
          <LinkSection {...printCenter} />
          <LinkSection {...celebrate} />
        </div>
      </div>
      <div className="mx-5 mt-6 flex gap-4 max-lg:flex-col max-md:bg-white">
        <a
          href={THE_RESOURCE_URLS[currentLocale]}
          target="_blank"
          rel="noreferrer"
          className="contents"
        >
          <ButtonSection title={t('theResource')} iconName="DesktopComputer" />
        </a>
        <a
          href={TRAINING_LIBRARY_URLS[currentLocale]}
          target="_blank"
          rel="noreferrer"
          className="contents"
        >
          <ButtonSection title={t('trainingLibrary')} iconName="AcademicCap" />
        </a>
      </div>
    </div>
  )
})
