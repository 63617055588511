import { I18nStore, LocalStorageItem } from '@nori/app-kit'
import { DataState, Inject, Store } from '@nori/di'

import { dashboardEventsLinks } from './dashboard-events-links'

import type { FinancialsErrorsInstance } from '../core/financials.errors'
import type { UserFinancialsValueObject } from '../core/user-financials.value-object'

const LS_KEY = '@nori/dashboard-fin-blur'

@Store()
export class DashboardStore {
  constructor(
    @Inject()
    private readonly i18nStore: I18nStore
  ) {}

  private blurState = LocalStorageItem<boolean>(LS_KEY, {
    initialValue: false,
  })

  isLoading = true

  financialsState = new DataState<
    UserFinancialsValueObject,
    FinancialsErrorsInstance
  >()

  get dashboardEventsLinks() {
    const local = this.i18nStore.currentLocale
    return dashboardEventsLinks[local]
  }

  setIsLoading(value: boolean): void {
    this.isLoading = value
  }

  get isFinancialsBlurred(): boolean {
    return !!this.blurState.value
  }

  toggleFinancialsBlurred(): void {
    this.blurState.set(!this.blurState.value)
  }
}
